<script>
export default {
  name: 'AmountCard',
  props: {
    amount: {
      type: String
    },
    name: {
      type: String
    },
    description: {
      type: String
    }
  }
}
</script>
<template>
  <div class="amount-card--container" @click="$emit('click')">
    <h4 class="amount-card--title">{{ amount }}</h4>
    <p class="amount-card--name">{{ name }}</p>
    <span class="amount-card--description">{{ description }}</span>
  </div>
</template>
<style lang="scss">
.amount-card--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 195px;
  max-width: 100%;
  padding: 40px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
  h4.amount-card--title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #272730;
    margin-bottom: 0;
  }
  p.amount-card--name {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #272730;
    margin: 0 0 8px 0;
  }
  span.amount-card--description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 900px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #272730;
  }
}
</style>
