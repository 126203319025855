<script>
import AmountCard from '@/components/general/AmountCard'
import InputField from '@/components/general/InputField'
export default {
  name: 'AmountSelection',
  props: {
    currencyReference: {
      type: String,
      default: 'BRL'
    },
    currentRates: {
      type: Object
    }
  },
  components: {
    AmountCard,
    InputField
  },
  data () {
    return {
      customAmount: 20
    }
  },
  methods: {
    checkCustomAmount () {
      if (this.customAmount < 20) {
        this.customAmount = 20
      }
    }
  }
}
</script>
<template>
  <div class="amount-selection--container">
    <div class="amount-selection--header">
      <h3>{{ $t('amount.selection:title') }}</h3>
    </div>
    <div class="amount-selection--options">
      <template v-if="currencyReference === 'BRL'">
        <amount-card
          amount="20"
          :name="$t('amount.selection:candidate')"
          description="R$ 160"
          @click="$emit('change', '20')"
        ></amount-card>
        <amount-card
          amount="50"
          :name="$t('amount.selection:candidate')"
          description="R$ 400"
          @click="$emit('change', '50')"
        ></amount-card>
        <amount-card
          amount="100"
          :name="$t('amount.selection:candidate')"
          description="R$ 800"
          @click="$emit('change', '100')"
        ></amount-card>
        <amount-card
          amount="500"
          :name="$t('amount.selection:candidate')"
          description="R$ 4 mil"
          @click="$emit('change', '500')"
        ></amount-card>
      </template>
      <template v-if="currencyReference === 'USD'">
        <amount-card
          amount="20"
          :name="$t('amount.selection:candidate')"
          description="$ 20"
          @click="$emit('change', '20')"
        ></amount-card>
        <amount-card
          amount="50"
          :name="$t('amount.selection:candidate')"
          description="$ 50"
          @click="$emit('change', '50')"
        ></amount-card>
        <amount-card
          amount="100"
          :name="$t('amount.selection:candidate')"
          description="$ 100"
          @click="$emit('change', '100')"
        ></amount-card>
        <amount-card
          amount="500"
          :name="$t('amount.selection:candidate')"
          description="$ 500"
          @click="$emit('change', '500')"
        ></amount-card>
      </template>
    </div>
    <div class="amount-selection--custom-amount">
      <p>{{ $t('amount.selection:custom.amount.tip') }}</p>
      <div class="amount-selection--input">
        <input-field
          outlined
          dense
          type="number"
          background-color="#fff"
          hide-validation
          v-model.number="customAmount"
          @blur="checkCustomAmount"
          :style="{'width': `${(String(customAmount).length * 12) + 40}px`, 'min-width': `${(String(customAmount).length * 12) + 40}px`}"
        ></input-field>
        <p>{{ $t('amount.selection:credits.calc', { value: customAmount * (currencyReference === 'BRL' ? currentRates.brl : currentRates.usd) }) }}</p>
      </div>
      <v-btn class="btn transform-unset" :dark="!(customAmount < 20)" :color="getPrimaryColor" :disabled="customAmount < 20" @click="$emit('change', customAmount)">{{ $t('global:add') }}</v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.amount-selection--container {
  .amount-selection--header {
    margin: 40px 0;
    h3 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #212121;
    }
  }
  .amount-selection--options {
    display: flex;
    justify-content: center;
    > * {
      margin: 0 16px;
    }
  }
  .amount-selection--custom-amount {
    margin-top: 40px;
    text-align: center;
    .amount-selection--input {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      .input-field--wrapper {
        width: unset;
        margin-right: 16px;
      }
      p {
        margin-bottom: 0;
      }
    }
    .btn {
      margin-top: 16px;
    }
  }
}
</style>
