<script>
import CurrencyCard from '@/components/general/CurrencyCard'
export default {
  components: { CurrencyCard },
  name: 'CurrencySelection',
  props: {
    currentRates: {
      type: Object
    }
  },
  data () {
    return {
      currency: this.currentRates,
      region: null
    }
  },
  created () {
    this.region = this.selectedWorkspace.region
  }
}
</script>
<template>
  <div class="currency-selection--container">
    <div class="currency-selection--header">
      <h3>{{ $t('currency.selection:title') }}</h3>
    </div>
    <div class="currency-selection--options">
      <currency-card
        v-if="region === 'USA' || region === 'BRA'"
        image="/assets/images/US.png"
        :currency="$t('currency.USD')"
        :description="$t('currency.USD.description', { value: currency.usd })"
        @click="$emit('change', 'USD')"
      ></currency-card>
      <currency-card
        v-if="region === 'BRA'"
        image="/assets/images/BR.png"
        :currency="$t('currency.BRL')"
        :description="$t('currency.BRL.description', { value: currency.brl })"
        @click="$emit('change', 'BRL')"
      ></currency-card>
    </div>
    <div class="currency-selection--footer">
      <ul>
        <li v-html="$t('currency.selection:footer.1')"></li>
        <li v-html="$t('currency.selection:footer.2')"></li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss">
.currency-selection--container {
  .currency-selection--header {
    margin: 40px 0;
    h3 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #212121;
    }
  }
  .currency-selection--options {
    display: flex;
    justify-content: center;
    > * {
      margin: 0 16px;
    }
  }
  .currency-selection--footer {
    margin-top: 40px;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    li {
      display: block;
      position: relative;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #777777;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -10px;
        top: 52%;
        transform: translateY(-50%);
        height: 4px;
        width: 4px;
        background: #777;
        border-radius: 100%;
      }
    }
  }
}
</style>
