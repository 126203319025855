<script>
export default {
  name: 'CurrencyCard',
  props: {
    image: {
      type: String
    },
    currency: {
      type: String
    },
    description: {
      type: String
    }
  }
}
</script>
<template>
  <div class="currency-card--container" @click="$emit('click')">
    <v-img :src="image" height="72px" width="72px"></v-img>
    <p class="currency-card--title">{{ currency }}</p>
    <span class="currency-card--description">{{ description }}</span>
  </div>
</template>
<style lang="scss">
.currency-card--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  max-width: 100%;
  padding: 40px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
  p.currency-card--title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #272730;
    margin: 24px 0 8px 0;
  }
  span.currency-card--description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 900px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #272730;
  }
}
</style>
