<script>
import CurrencySelection from './CurrencySelection'
import HorizontalSteps from '@/components/general/HorizontalSteps'
import AmountSelection from './AmountSelection'
import PaymentSelection from './PaymentSelection'
import ConclusionPayment from './ConclusionPayment'
import Breadcrumbs from '@/components/general/Breadcrumbs'
export default {
  components: { HorizontalSteps, CurrencySelection, AmountSelection, PaymentSelection, ConclusionPayment, Breadcrumbs },
  name: 'AddCredit',
  data () {
    return {
      horizontalSteps: [
        'add.credit:horizontal.steps.1',
        'add.credit:horizontal.steps.2',
        'add.credit:horizontal.steps.3',
        'add.credit:horizontal.steps.4'
      ],
      formData: {
        currency: null,
        amount: null,
        payment: null
      },
      rates: {
        brl: null,
        usd: null
      }
    }
  },
  computed: {
    actualStep () {
      if (this.formData.payment) return 3
      if (this.formData.amount) return 2
      if (this.formData.currency) return 1
      return 0
    },
    breadcrumbsPaths () {
      const path = [
        {
          text: 'admin.center:menu.7:title',
          href: this.$router.resolve({ name: 'business.admin.center.payment' }).href
        },
        {
          text: 'admin.center:add.credit.title',
          href: this.$route.path
        }
      ]
      return path
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('attempGetCurrencyRate'),
      this.$store.dispatch('attempNotifyTrack', { track: { event: 'wallet_add_credit' } })
    ]).then((data) => {
      this.rates.brl = data[0].data.brl
      this.rates.usd = data[0].data.usd
    })
  }
}
</script>
<template>
  <section class="add-credit--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths"/>
      <h2>{{ $t('admin.center:add.credit.title') }}</h2>
    </div>
    <div class="add-credit--body">
      <horizontal-steps :value="actualStep" :steps="horizontalSteps"></horizontal-steps>
      <currency-selection v-if="actualStep === 0" @change="currency => formData.currency = currency" :currentRates="rates"></currency-selection>
      <amount-selection v-if="actualStep === 1" :currencyReference="formData.currency" @change="amount => formData.amount = amount" :currentRates="rates"></amount-selection>
      <payment-selection v-if="actualStep === 2" :isBRL="formData.currency === 'BRL'" :credits="formData.amount" :currentRates="rates" @change="payment => formData.payment = payment"></payment-selection>
      <conclusion-payment v-if="actualStep === 3" :paymentType="formData.payment"></conclusion-payment>
    </div>
  </section>
</template>
<style lang="scss">
.add-credit--container {
  padding-bottom: 100px;
}
</style>
